<!-- 新增||编辑问答 组件 -->
<template>
  <div class="qaadd-container">
    <!-- 抽屉区域 -->
    <el-drawer
      :title="type == 'add' ? '新增问答' : '编辑问答'"
      :visible.sync="drawer"
      direction="rtl"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <!-- 主体内容 -->
      <el-form
        :model="qaForm"
        :rules="formRules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="问题名称" prop="questionName">
          <el-input
            class="common-screen-input_50"
            v-model="qaForm.questionName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="问题分类" prop="typeId">
          <el-select
            class="common-screen-input_50"
            v-model="qaForm.typeId"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
              clearable
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="触发规则" class="must" prop="a">
          <span class="keyword">关键词</span>
          <!-- 固定项目 -->
          <!-- <el-input
            class="common-screen-input_50"
            v-model="qaForm.keyWord"
            clearable
            style="display: none"
          ></el-input> -->
          <!-- 动态增加关键词 -->
          <div v-for="(item, i) of domains" :key="i">
            <el-input
              class="add-ipt common-screen-input_50"
              v-model="domains[i]"
              clearable
            ></el-input>
            <el-button
              class="del-ipt"
              type="text"
              @click.prevent="removeDomain(item)"
            >
              删除
            </el-button>
          </div>

          <div>
            <el-button
              class="add-btn add-ipt common-screen-input_50"
              @click="addDomain"
              ><i class="el-icon-plus"></i>添加
            </el-button>
          </div>
        </el-form-item>
        <!-- 且不包含 -->
        <!-- <el-form-item prop="notWord">
          <span class="noincluded">且不包含</span>
          <el-input
            class="common-screen-input_85"
            type="textarea"
            v-model="qaForm.notWord"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="回答设置" prop="wordContent">
          <el-input
            class="common-screen-input_85"
            type="textarea"
            v-model="qaForm.wordContent"
            clearable
          ></el-input>
        </el-form-item>
        <!-- 图片隐藏了 -->
        <!-- <el-form-item prop="imgContent">
          <div v-if="qaForm.imgContent">
            <img :src="qaForm.imgContent" style="width: 100px; height: 100px" />
          </div>
          <div class="upload-userIcon" style="width: 100px">
            <el-button
              style="margin: 0"
              class="common-screen-btn"
              type="primary"
              icon="el-icon-plus"
              >上传图片
            </el-button>
            <input
              class="common-upload-input"
              @input="chooseFile"
              type="file"
              style="width: 100px"
            />
            <i class="el-icon-close" @click="handleRemove"></i>
            <img style="height:100%;width:100%;" v-if="true" src=""/>
          </div>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
// import { Config } from "../../../utils/index.js";
import { upload } from "../../../service/upload.js";
import { editQuestion, addQuestion, questionList } from "../../../service/auto";

export default {
  name: "",
  components: {},
  props: {
    drawer: Boolean
  },
  data() {
    return {
      type: "",
      id: 0,
      //   抽屉里form
      qaForm: {
        shopId: 0
      }, //抽屉form数据
      formRules: {
        questionName: [
          { required: true, message: "请填写名称", trigger: "blur" }
        ],
        typeId: [{ required: true, message: "请填写名称", trigger: "blur" }],
        // keyWord: [
        //   { required: true, message: "关键词不能为空", trigger: "blur" }
        // ],
        // a: [{ required: true, message: "请填写名称", trigger: "blur" }],
        wordContent: [
          { required: true, message: "请输入自动回复内容", trigger: "blur" }
        ]
      },
      classList: [], //抽屉>form>问题分类下拉
      // 新增input
      domains: [""]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 发送请求
          let domains = [...this.domains];
          let qaForm = { ...this.qaForm };
          console.log(domains, "/////////////////////");
          if (domains.length >= 1) {
            domains = domains.filter((item) => item);
            if (domains.length == 0) {
              this.$message.error("请填写完整的信息");
              // 改样式
              return;
            }
            qaForm.keyWord = domains.join("/");
          }

          // this.domains = domains.split('/')
          console.log(qaForm, "****************************");
          if (this.type == "edit") {
            const res = await editQuestion(qaForm, this.id);
            if (res.code !== 200) return this.$message.error("修改失败！");
            this.$message.success("修改成功！");
            this.$emit("refash");
          } else {
            const res = await addQuestion(qaForm);
            if (res.code !== 200) return this.$message.error("新增失败！");
            this.$message.success("新增成功！");
            this.$emit("refash");
          }

          this.handleClose();
        } else {
          console.log("error submit!!");
          this.$message.error("请填写完整的信息");
          // return false;
        }
      });
      // this.$refs["ruleForm"].resetFields();
    },
    // 取消键
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.handleClose();
    },
    // 判断type
    getInfo(type, row) {
      if (type == "add") {
        this.qaForm = {};
        this.qaForm.shopId = JSON.parse(this.$route.query.shopid);
        this.domains = [""]
        // if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
        this.type = type;
        this.getSortList();
      } else {
        this.qaForm = {
          questionName: row.question_name,
          typeId: row.typeId,
          keyWord: row.key_word.split("/"),
          notWord: row.not_word,
          wordContent: row.word_content,
          imgContent: row.img_content
        };
        this.qaForm.shopId = JSON.parse(this.$route.query.shopid);
        this.domains = this.qaForm.keyWord; //动态增加
        this.type = type;
        this.id = row.id;
        this.getSortList();
      }
    },
    handleClose(done) {
      this.$emit("handleClose", done);
    },
    // 删除上传图片
    handleRemove() {
      this.qaForm.imgContent = "";
    },
    // 上传图片
    async chooseFile(e) {
      let qaForm = { ...this.qaForm };
      let resData = (await upload({ file: e.target.files[0] })).data;
      this.qaForm = Object.assign(qaForm, { imgContent: resData.filename });
    },
    // input 框后面删除
    removeDomain(item) {
      var index = this.domains.indexOf(item);
      if (index !== -1) {
        this.domains.splice(index, 1);
        if (this.domains.length == 1) {
          this.domains.toString();
          console.log(typeof this.domains.toString(), "-////////");
        }
      }
    },
    // 新增 input
    addDomain() {
      this.domains.push("");
    },
    // 所属分类下拉
    async getSortList() {
      try {
        const { data: res } = await questionList({}, this.qaForm.shopId);
        this.classList = res;
      } catch (error) {
        console.log(error);
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {}
};
</script>

<style lang="less" scoped>
.demo-ruleForm {
  // /deep/.el-input__inner {
  //   width: auto;
  // }
  // /deep/.el-textarea__inner {
  //   width: 80%;
  // }
  .keyword {
    margin-right: 20px;
    color: #606266;
  }
  .noincluded {
    margin-right: 10px;
    color: #606266;
  }
  .add-ipt {
    margin-left: 62px;
  }
  .del-ipt {
    margin-left: 16px;
  }
  .add-btn {
    border: 1px dashed #d9d9d9;
  }
  .upload-userIcon {
    position: relative;
    .el-icon-close {
      position: absolute;
      top: 78px;
      left: 118px;
      font-size: 25px;
    }
  }
}
/deep/.el-drawer {
  width: 40% !important;
}
/deep/.common-screen-btn {
  margin: 0px;
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  color: #575757;
  border: 1px dashed #d9d9d9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
// .el-form-item__label:before:nth-child(3n) {

// }
.must {
  position: relative;
  &::before {
    content: "*";
    color: #f56c6c;
    margin-right: 4px;
    position: absolute;
    top: 10px;
    left: 42px;
  }
}
</style>
